import { RiLogoutBoxRLine } from '@remixicon/react'
import { Avatar, Button, Divider, Popover, Tooltip } from 'antd'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'

import useLoadingState from '@/hooks/context/useLoadingState'
import useAuth from '@/hooks/useAuth'
import useDbSettings from '@/hooks/useDbSettings'

import { API_URL, APP_URL } from '@/constants/env'
import { cn } from '@/utils/clsx'

import LanguageSwitch from '../LanguageSwitch'
import ThemeSwitch from '../ThemeSwitch'
import packageJson from '../../../../package.json'

const ProfileBadge: React.FC = () => {
  const { t } = useTranslation()
  const { startLoading } = useLoadingState()
  const { user } = useAuth()
  const { settings } = useDbSettings()

  const signUpCompleted =
    (settings?.requireSubscription && user?.subscribed) ||
    (!settings?.requireSubscription &&
      settings?.useDocumenso &&
      !user?.signed) ||
    (!settings?.requireSubscription && !settings?.useDocumenso)

  if (!user?.email) {
    return null
  }

  return (
    <Popover
      placement='bottomRight'
      trigger='click'
      content={
        <div className='flex min-w-[200px] max-w-[300px] flex-col'>
          <div className='truncate text-base font-semibold'>{user?.name}</div>
          <div className='truncate text-sm'>{user?.email}</div>
          {!signUpCompleted && (
            <>
              <Divider plain className='my-3' />
              <Link href={`${APP_URL}/signup`} onClick={() => startLoading()}>
                <Button className='bg-warning dark:bg-dark-warning'>
                  {t('sign-up-warning-complete')}
                </Button>
              </Link>
              <Divider plain className='my-3' />
            </>
          )}
          <LanguageSwitch className='mt-3 self-start' />
          <ThemeSwitch className='mt-3' />
          <div className='mt-6 text-xs text-opacity-60'>
            {t('contact-us-terms-privacy-text')}{' '}
            <Link
              href='https://pinnaclemindsinc.com/terms-and-conditions/'
              target='_blank'
              className='text-on-surface hover:text-on-surface/60 dark:text-dark-on-surface hover:dark:text-dark-on-surface/60'
            >
              {t('contact-us-terms-and-conditions')}
            </Link>
            <br />
            {t('contact-us-terms-privacy-connect-text')}{' '}
            <Link
              href='https://pinnaclemindsinc.com/privacy-policy/'
              target='_blank'
              className='text-on-surface hover:text-on-surface/60 dark:text-dark-on-surface hover:dark:text-dark-on-surface/60'
            >
              {t('contact-us-privacy-policy')}
            </Link>
            <p className='mt-2'>
              {t('version')} {packageJson.version}
            </p>
          </div>
          <Divider plain className='my-3' />
          <Link
            href={`${API_URL}/auth/logout`}
            className='w-fit text-base'
            onClick={() => startLoading()}
          >
            <Button
              className='flex items-center'
              icon={<RiLogoutBoxRLine className='size-4' />}
            >
              {t('logout')}
            </Button>
          </Link>
        </div>
      }
    >
      <Tooltip
        open={!signUpCompleted}
        title={t('sign-up-warning-complete')}
        placement='left'
      >
        <Avatar
          className={cn(
            'cursor-pointer',
            signUpCompleted
              ? 'text-on-primary dark:text-dark-on-primary bg-primary dark:bg-dark-primary'
              : 'text-on-warning dark:text-dark-on-warning bg-warning dark:bg-dark-warning'
          )}
        >
          <span className='text-lg'>
            {signUpCompleted
              ? (user.name ?? '').slice(0, 1).toUpperCase()
              : '!'}
          </span>
        </Avatar>
      </Tooltip>
    </Popover>
  )
}

export default ProfileBadge
