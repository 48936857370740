import { SITE_ID } from './env'

export const configHeader = {
  headers: {
    'Content-Type': 'application/json',
    Site: SITE_ID as string,
  },
}

export const configFileHeader = {
  headers: {
    'Content-Type': 'multipart/form-data',
    Site: SITE_ID as string,
  },
}
