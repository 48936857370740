'use client'

import { setCookie } from 'cookies-next'
import { changeLanguage, t } from 'i18next'

import en from './locales/en.json'
import es from './locales/es.json'

export const translate = (key: string) => {
  return t(key)
}

export const switchLanguage = (lang: string) => {
  changeLanguage(lang)
  setCookie('locale', lang)
}

export const translations = {
  en: {
    translation: en,
  },
  // slo: {
  //   translation: slo,
  // },
  es: {
    translation: es,
  },
}
