export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const APP_URL = process.env.NEXT_PUBLIC_APP_URL
export const SITE_ID = process.env.NEXT_PUBLIC_SITE_ID
export const LANGUAGE = process.env.NEXT_PUBLIC_LANGUAGE
export const COMPANY = process.env.NEXT_PUBLIC_COMPANY
export const AGENT_NAME = process.env.NEXT_PUBLIC_AGENT_NAME
export const OAUTH2_PROVIDER = process.env.NEXT_PUBLIC_ACTIVE_OAUTH2_PROVIDER
export const DEFAULT_USE_CONTACT_US_CTA =
  process.env.NEXT_PUBLIC_DEFAULT_USE_CONTACT_US_CTA === 'true'
export const DEFAULT_USE_CUSTOMIZABLE_PROMPT =
  process.env.NEXT_PUBLIC_DEFAULT_USE_CUSTOMIZABLE_PROMPT === 'true'
export const NEXT_PUBLIC_STRIPE_PRODUCT_ID =
  process.env.NEXT_PUBLIC_STRIPE_PRODUCT_ID
export const NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY =
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
export const NEXT_PUBLIC_WEBSOCKET_URL = process.env.NEXT_PUBLIC_WEBSOCKET_URL
