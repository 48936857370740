'use client'

import localFont from 'next/font/local'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { logoIndexClx } from '@/branding-config'
import { COMPANY } from '@/constants/env'
import { cn } from '@/utils/clsx'

import IndexDropdown from '../IndexHeader/IndexDropdown'
import IndexNavigation from '../IndexHeader/IndexNavigation'
import companyLogo from '../../../public/companyLogo.png'

const pinnacleFont = localFont({ src: '../../../public/Saans-Regular-1.woff2' })

const Header: React.FC = () => {
  const { t } = useTranslation()

  return (
    <section className='flex flex-col justify-center p-4 text-on-background lg:h-28 xl:px-20 dark:text-dark-on-background'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-6 md:gap-14'>
          <Link href='/' className='flex items-center gap-6'>
            <Image
              src={companyLogo}
              alt='company logo'
              className={cn('cursor-pointer', logoIndexClx)}
            />
            <div className='flex flex-col text-left'>
              <span
                className={cn(
                  'text-xl font-bold text-on-surface dark:text-dark-on-surface',
                  pinnacleFont.className
                )}
              >
                {COMPANY}
              </span>
              <div className='hidden gap-1 text-sm text-on-surface/60 md:block dark:text-dark-on-surface/60'>
                {t('index-header')}
              </div>
            </div>
          </Link>
          <div className='hidden gap-10 sm:flex'>
            <Link
              href='#features'
              type='text'
              className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
            >
              Features
            </Link>
            <Link
              href='#testimonials'
              type='text'
              className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
            >
              Testimonials
            </Link>
            <Link
              href='#pricing'
              type='text'
              className='text-on-surface hover:text-on-surface/70 dark:text-dark-on-surface hover:dark:text-dark-on-surface/70'
            >
              Pricing
            </Link>
          </div>
        </div>
        <div>
          <div className='flex lg:hidden'>
            <IndexDropdown />
          </div>
          <div className='hidden lg:flex'>
            <IndexNavigation />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Header
