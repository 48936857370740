import { createContext } from 'react'

import { LoadingProps } from './loading.types'

export const initialState: { loading: boolean; progress: number } = {
  loading: true,
  progress: 0,
}

export const LoadingContext = createContext<LoadingProps>({
  ...initialState,
  startLoading: () => undefined,
  stopLoading: () => undefined,
})
