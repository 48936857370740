'use client'

import { RiCheckLine, RiCloseLine } from '@remixicon/react'
import { Button, Card } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/navigation'

import useLoadingState from '@/hooks/context/useLoadingState'
import useAuth from '@/hooks/useAuth'

import { APP_URL } from '@/constants/env'

import Reveal from './Reveal'

const Pricing: React.FC = () => {
  const { user } = useAuth()
  const router = useRouter()
  const { startLoading } = useLoadingState()

  return (
    <section id='pricing' className='w-full py-10 md:py-20 lg:py-32'>
      <div className='mx-auto flex max-w-screen-xl flex-col gap-16 px-4 text-left text-on-background dark:text-dark-on-background'>
        <Reveal
          type='slideUp'
          className='flex flex-col gap-5 text-left md:text-center'
        >
          <h3 className='text-base opacity-80 sm:text-xl'>Pricing</h3>
          <h2 className='text-3xl font-bold md:text-4xl lg:text-5xl'>
            <span className='text-primary dark:text-dark-primary'>
              Affordable Pricing
            </span>{' '}
            for Businesses of All Sizes
          </h2>
          <p className='text-base opacity-80 md:text-lg lg:text-xl'>
            Choose the plan that best fits your business needs and budget. Get
            started with our free plan or upgrade to unlock more features and
            support.
          </p>
        </Reveal>
        <Reveal
          type='slideUp'
          className='m-auto grid w-full max-w-screen-md grid-cols-1 gap-6 sm:grid-cols-2'
        >
          <Card>
            <div className='flex h-full flex-col justify-between gap-5'>
              <div className='flex flex-col gap-5'>
                <div className='flex flex-col'>
                  <h2 className='text-xl font-semibold'>Pro</h2>
                  <h3 className='text-4xl font-semibold'>$2,000</h3>
                  <h4 className='text-sm opacity-80'>per month</h4>
                </div>
                <p className='text-base'>
                  Perfect for small businesses and startups looking to
                  streamline their grant search and application process.
                </p>
                <div className='flex flex-col'>
                  <div className='flex items-center gap-2'>
                    <RiCheckLine className='size-4 text-secondary' />
                    AI-powered grant research
                  </div>
                  <div className='flex items-center gap-2'>
                    <RiCheckLine className='size-4 text-secondary' />
                    Application document generation
                  </div>
                  <div className='flex items-center gap-2'>
                    <RiCheckLine className='size-4 text-secondary' />
                    Custom grant recommendations
                  </div>
                  <div className='flex items-center gap-2'>
                    <RiCheckLine className='size-4 text-secondary' />
                    Email support
                  </div>
                  <div className='flex items-center gap-2'>
                    <RiCloseLine className='size-4' />
                    Custom grant application support
                  </div>
                  <div className='flex items-center gap-2'>
                    <RiCloseLine className='size-4' />
                    Monthly consultation call
                  </div>
                </div>
              </div>
              <Link href={`${APP_URL}/signup`} onClick={() => startLoading()}>
                <Button
                  type='primary'
                  size='large'
                  onClick={() => router.push('/signup')}
                  disabled={user?.subscribed || user?.role === 'ADMIN'}
                  className='w-full'
                >
                  {!user
                    ? 'Sign in'
                    : user?.role === 'ADMIN'
                      ? 'You are Admin'
                      : user?.subscribed
                        ? 'Subscribed'
                        : 'Get Started'}
                </Button>
              </Link>
            </div>
          </Card>
          <Card>
            <div className='flex h-full flex-col justify-between gap-5'>
              <div className='flex flex-col gap-5'>
                <div className='flex flex-col'>
                  <h2 className='text-xl font-semibold'>Enterprise</h2>
                  <h3 className='text-4xl font-semibold'>Custom quote</h3>
                  <h4 className='text-sm opacity-80'>per month</h4>
                </div>
                <p className='text-base'>
                  Ideal for growing businesses and established companies seeking
                  additional support and features.
                </p>
                <div className='flex flex-col'>
                  <div className='flex items-center gap-2'>
                    <RiCheckLine className='size-4 text-secondary' />
                    AI-powered grant research
                  </div>
                  <div className='flex items-center gap-2'>
                    <RiCheckLine className='size-4 text-secondary' />
                    Application document generation
                  </div>
                  <div className='flex items-center gap-2'>
                    <RiCheckLine className='size-4 text-secondary' />
                    Custom grant recommendations
                  </div>
                  <div className='flex items-center gap-2'>
                    <RiCheckLine className='size-4 text-secondary' />
                    Priority email support
                  </div>
                  <div className='flex items-center gap-2'>
                    <RiCheckLine className='size-4 text-secondary' />
                    Custom grant application support
                  </div>
                  <div className='flex items-center gap-2'>
                    <RiCheckLine className='size-4 text-secondary' />
                    Monthly consultation call
                  </div>
                </div>
              </div>
              <Button size='large'>Contact Us</Button>
            </div>
          </Card>
        </Reveal>
      </div>
    </section>
  )
}

export default Pricing
