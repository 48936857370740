'use client'

import { motion, useAnimation, useInView } from 'framer-motion'
import { PropsWithChildren, useEffect, useRef } from 'react'

interface RevealProps extends PropsWithChildren {
  type: 'slideUp' | 'slideLeft'
  className?: string
}

const Reveal: React.FC<RevealProps> = ({ type, children, className }) => {
  const ref = useRef<HTMLDivElement>(null)
  const isInView = useInView(ref, { once: true })

  const animationControls = useAnimation()

  useEffect(() => {
    if (isInView) {
      if (type === 'slideUp') {
        animationControls.start('visible')
      } else if (type === 'slideLeft') {
        animationControls.start('visible')
      }
    }
  }, [isInView, animationControls])

  const initial =
    type === 'slideUp' ? { opacity: 0, y: 50 } : { opacity: 0, x: 100 }
  const variants =
    type === 'slideUp'
      ? {
          hidden: {
            opacity: 0,
            y: 50,
          },
          visible: {
            opacity: 1,
            y: 0,
          },
        }
      : {
          hidden: {
            opacity: 0,
            x: -50,
          },
          visible: {
            opacity: 1,
            x: 0,
          },
        }

  return (
    <div ref={ref} className='relative overflow-hidden'>
      <motion.div
        variants={variants}
        initial={initial}
        animate={animationControls}
        transition={{ duration: 0.5, delay: 0.25 }}
        className={className}
      >
        {children}
      </motion.div>
    </div>
  )
}

export default Reveal
